











import { computed } from '@vue/composition-api';

export default {
  props: {
    value: {
      type: [String, Number, Boolean]
    },
    items: {
      type: Array,
      default: () => []
    },
    styling: {
      type: Object,
      default: () => {}
    },
    defaultValue: {
      type: [String, Number, Boolean]
    },
    disabled: {
      required: false,
      type: Boolean
    }
  },

  setup(props, ctx) {
    const inputValue = computed({
      get: () => props.value || props.defaultValue,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    return { inputValue };
  }
};
